import * as moment from 'moment';

export class StaticUtils {
  static dateTimeFormat = 'DD.MM.YYYY HH:mm';
  static dateFormat = 'DD.MM.YYYY';
  static timeFormat = 'HH:mm';

  // DATE AND TIME
  static formatDateTime(dateTime: moment.Moment): string {
    dateTime = dateTime.clone();
    return dateTime.format(this.dateTimeFormat);
  }

  static formatDate(date: moment.Moment): string {
    date = date.clone();
    return date.format(this.dateFormat);
  }

  static formatDateRange(dateStart: moment.Moment, dateEnd: moment.Moment): string {
    dateStart = dateStart.clone();
    dateEnd = dateEnd.clone();
    return dateStart.format('DD.MM') + ' - ' + this.formatDate(dateEnd);
  }

  static formatTime(time: moment.Moment): string {
    time = time.clone();
    return time.format(this.timeFormat);
  }

  static formatDuration(time: moment.Moment): string {
    time = time.clone();
    return time.format('HH') + 'h' + time.format('mm');
  }

  static formatDateForBackend(date: moment.Moment): string {
    date = date.clone();
    return date.format('YYYY-MM-DD') + ' 00:00:00';
  }

  static formatDateTimeForBackend(dateTime: moment.Moment): string {
    dateTime = dateTime.clone();
    return dateTime.format('YYYY-MM-DD HH:mm:ss');
  }

  // TICKETS
  static formatTicketNumber(id: number | string): string {
    return '#' + '0'.repeat(5 - id.toString().length) + id;
  }

  static convertValueToCurrency(value: number): string {
    return value ? value.toFixed(2).toString().replace('.', ',') + ' €' : '0,00 €';
  }

  static convertValueToPercentage(value: number): string {
    return value !== undefined ? value + '%' : '0%';
  }

  static convertValueFromType(value: number, type: number): string {
    let result = '';

    if (type === 1) {
      result = StaticUtils.convertValueToPercentage(value);
    } else if (type === 0) {
      result = StaticUtils.convertValueToCurrency(value);
    }

    return result;
  }

  // OBJECTS
  static deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = StaticUtils.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = StaticUtils.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }

  static objectKeys(obj) {
    return Object.keys(obj);
  }

  static groupBy(array: any[], callback: (v) => {}): any {
    return array.reduce((r, v, i, a, k = callback(v)) => {
      return ((r[k] || (r[k] = [])).push(v), r);
    }, {});
  }

  // IMAGES
  static prepareBase64Image(base64: string) {
    let result = base64;

    if (!result.startsWith('data:')) {
      result = 'data:image/jpeg;base64,' + result;
    }

    return result;
  }

  // SORT COMPARE
  static compareStrings(a: string, b: string) {
    let result = 0;

    const aValue = a.toUpperCase();
    const bValue = b.toUpperCase();

    if (aValue > bValue) {
      result = 1;
    } else if (aValue < bValue) {
      result = -1;
    }

    return result;
  }
}
