import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../shared/global.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorMessage = '';

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _globalService: GlobalService) { }

  ngOnInit() {
    this.errorMessage = this._globalService.errorMessage || '';
  }

  done() {
    this._globalService.clean();
    this._router.navigate(['../home'], { relativeTo: this._route, queryParamsHandling: 'merge' });
  }
}
