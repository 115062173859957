import { Injectable } from '@angular/core';
import {StripeService} from 'ngx-stripe';

@Injectable()
export class LocalStripeService {

  constructor(private _stripeService: StripeService) { }

  public handleCardPayment(clientSecret: string, cardElement: any): Promise<any> {

    // @ts-ignore
    return this._stripeService.getInstance().handleCardPayment(clientSecret, cardElement);
  }
}
