import { Component, AfterViewInit, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from "../shared/api/model/product";
import { Event } from "../shared/api/model/event";
import * as moment from "moment";
import { SettingsService } from "../shared/api/resources/settings/settings.service";
import { GlobalService } from "../shared/global.service";
import { WidgetService } from "../shared/api/widget/widget.service";
import { ToasterService } from "../shared/atoms/toaster/toaster.service";
import { Category } from "../shared/api/model/category";
import { StaticUtils } from "../shared/utils/static-utils";
import { Booking } from "../shared/api/model/booking";
import { CartService } from "../shared/cart/cart.service";
import { environment } from "../../environments/environment";
import { GtmCustomService } from '../gtm-custom.service';

declare let gtag: Function;

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}
@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  moment = moment;
  utils = StaticUtils;
  categories: Category[];
  selectedCategory: Category;
  categoriesAndProductsOptions: {
    label: string;
    value: Category | Product;
    type: string;
  }[];
  products: Product[];
  selectedProduct: Product;
  productsOptions: { label: string; value: Product }[];
  productEvents: Event[];
  events: Event[];
  reloadingEvents = false;
  initialized = false;
  dateValue: { start: moment.Moment; end: moment.Moment };
  daysWithEvents = [];
  daysWithEventsPromise: Promise<moment.Moment>;
  private receivedGtmId: string;
  private receivedGa4Id: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService,
    private _settingsService: SettingsService,
    private _widgetService: WidgetService,
    private _cartService: CartService,
    private _toasterService: ToasterService,
    private gtmCustomService: GtmCustomService
  ) {}

  async ngOnInit() {
    this._globalService.clean();

    let startDate = moment();
    const cart = await this._cartService.getCart(false);
    if (cart && cart.bookings) {
      let booking: Booking;
      for (let i = 0; i < cart.bookings.length; i++) {
        if (!booking || cart.bookings[i].id > booking.id) {
          booking = cart.bookings[i];
        }
      }
      if (booking) {
        startDate = moment(booking.event.start);
      }
    }

    this.dateValue = {
      start: startDate,
      end: null,
    };

    this._widgetService.getInfo().subscribe((data) => this._initData(data));
    this.dateValue.start = moment(this._globalService.event_date);
  }

  private async _initData(data: any): Promise<void> {
    this.products = data.products;
    this.categories = data.categoryList;
    this.productsOptions = [];

    environment.isToHidePromocode = data.isToHidePromocode;

    this.receivedGtmId = data.gtagId || '';
    this.receivedGa4Id = data.googleAnalyticsMeasurementId || '';

    // Loads and starts GTM
    this.gtmCustomService.initGtm(this.receivedGtmId);

    // Load GA4
    await this.gtmCustomService.loadAndInitGa4(this.receivedGa4Id);

    // After GA4 is loaded, define consent
    this.gtmCustomService.updateConsent(this.receivedGa4Id);

    // Remove invalid <noscript> and add the valid one
    this.gtmCustomService.removeInvalidNoscriptTagsAndAddValidOne(this.receivedGtmId);
 
    await this._updateCategoriesAndProductList();
    this.initialized = true;
  }

  ngAfterViewInit() {
    // If app is inside an iframe, it makes the necessary adjustments
    if (this.gtmCustomService.isInIframe()) {
      this.gtmCustomService.updateConsent(this.receivedGa4Id);
    }

    // Remove unwanted scripts injected by lib
    this.gtmCustomService.removeInvalidGtmScripts();
  }

  // private configureGA4(ga4Id): void {
  //   if (typeof gtag === 'function') {
  //     gtag('js', new Date());
  //     gtag('config', ga4Id, {
  //       'client_id': this.getClientIdFromUrl(),
  //       'session_id': this.getSessionIdFromUrl(),
  //       'cookie_flags': 'SameSite=None;Secure;Partitioned'
  //     });
  //   }
  // }

  //  private getClientIdFromUrl(): string | null {
  //   let clientId: string | null = null;
  //   this._route.queryParams.subscribe(params => {
  //     clientId = params['clientId'] || null;
  //   });
  //   return clientId;
  // }

  // private getSessionIdFromUrl(): string | null {
  //   let sessionId: string | null = null;
  //   this._route.queryParams.subscribe(params => {
  //     sessionId = params['sessionId'] || null;
  //   });
  //   return sessionId;
  // }

  public async goToBook(event): Promise<void> {
    if (this.isButtonDisabled(event)) return;
    if (
      !event.isSellableTillEnd
        ? moment(event.end).isBefore(moment())
        : moment(event.start).isAfter(moment())
    )
      return Promise.resolve();

    if (!this.selectedProduct) {
      this.selectedProduct = event.product;
      this._updateGlobalService();
    }

    this._globalService.event = event;
    this._globalService.eventGenderPrices = event.eventGenderPrices;

    // Create booking object
    const booking = new Booking();

    // Handle gender prices
    booking.bookingGenderPrices = event.eventGenderPrices;
    const isPerOrder = booking.bookingGenderPrices.some(
      (genderPrice) => genderPrice.genderPriceType === 1
    );

    if (booking.bookingGenderPrices.length > 0 && isPerOrder) {
      // it should only be one fake, but you never know...
      const fakeGenderPricesIndexes = booking.bookingGenderPrices.map(
        (genderPrice, index) =>
          genderPrice.genderPriceType === 1 &&
          genderPrice.isExcludedFromBilling === false
            ? index
            : null
      );
      for (const index of fakeGenderPricesIndexes) {
        if (index !== null) {
          booking.bookingGenderPrices[index].quantityToBook = 1;
        }
      }
    }

    // Add event to booking
    booking.event = this._globalService.event;

    // Update global service booking object
    this._globalService.booking = booking;

    // Navigate
    if (isPerOrder) {
      const gpNotExcludedAmount = booking.bookingGenderPrices.filter(
        (gp) => !gp.isExcludedFromBilling
      ).length;
      const gpPerOrderAmount = booking.bookingGenderPrices.filter(
        (gp) => gp.genderPriceType === 1
      ).length;

      if (gpPerOrderAmount > gpNotExcludedAmount) {
        await this._router.navigate(["../pax/"], {
          relativeTo: this._route,
          queryParamsHandling: "merge",
        });
      } else {
        if (event.product.extras.length > 0 && event.widgetHaveAccessExtras) {
          await this._router.navigate(["../add/"], {
            relativeTo: this._route,
            queryParamsHandling: "merge",
          });
        } else if (
          event.eventRoutes.length > 0 &&
          event.widgetHaveAccessPickups
        ) {
          await this._router.navigate(["../transport/"], {
            relativeTo: this._route,
            queryParamsHandling: "merge",
          });
        } else {
          try {
            this._globalService.loading = true;
            await this._cartService.addBookingToCart(
              this._globalService.booking
            );
            this._globalService.clearBookingProductAndEvent();
            await this._router.navigate(["cart"], {
              queryParamsHandling: "merge",
            });
          } catch (e) {
            this._toasterService.showError(e);
          } finally {
            this._globalService.loading = false;
          }
        }
      }
    } else {
      await this._router.navigate(["../pax/"], {
        relativeTo: this._route,
        queryParamsHandling: "merge",
      });
    }
  }

  private async _updateCategoriesAndProductList(): Promise<void> {
    if (this.categories.length > 0) {
      this.categoriesAndProductsOptions = this.categories.map((category) => {
        const products = this.products.filter((product) =>
          product.categoryList.some(
            (categoryItem) => categoryItem.id === category.id
          )
        );
        return {
          isExpanded: true,
          label: category.name,
          value: category,
          type: "category",
          children: products.map((product) => ({
            label: product.name,
            value: product,
            type: "product",
          })),
        };
      });
    }

    // Get products without categories
    const productsWithoutCategory = this.products.filter(
      (product) =>
        !this.categories.some((category) =>
          product.categoryList.some(
            (productCategory) => category.id === productCategory.id
          )
        )
    );

    // Get products without categories options
    const productsWithoutCategoryOptions = productsWithoutCategory
      .reduce((unique, product) => {
        return unique.find((item) => item.id === product.id)
          ? unique
          : [...unique, product];
      }, [])
      .map((product) => ({
        label: product.name,
        value: product,
        type: "product",
      }));

    // If there are no categories, only populate children with no category
    if (this.categories.length === 0) {
      this.categoriesAndProductsOptions = productsWithoutCategoryOptions;
    } else {
      // Add products without categories options and sort all options alphabetically
      this.categoriesAndProductsOptions = this.categoriesAndProductsOptions
        .concat(productsWithoutCategoryOptions)
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    // If there is only one option handle its selection
    if (this.categoriesAndProductsOptions.length === 1) {
      await this.handleCategoryOrProductChange(
        this.categoriesAndProductsOptions[0].value,
        this.categoriesAndProductsOptions[0].type
      );
    }
  }

  public async onProductSelect(product: Product): Promise<void> {
    this.selectedProduct = product;
    this._updateGlobalService();
    await this.reloadEvents();
  }

  public async reloadEvents(): Promise<void> {
    if (!this.dateValue) throw new Error("Date value is not defined");
    if (!this.selectedProduct || !this.selectedCategory) {
      console.log("No product or category selected");
    }

    this.startLoading();

    try {
      if (!this.selectedProduct || !this.selectedProduct.id) {
        // console.error("No product or category selected");
        return;
      }
      // this line transforms the id from string to number
      this.selectedProduct.id = +this.selectedProduct.id;

      this.events = await this.getEventsForProduct({
        productId: this.selectedProduct.id,
        date: this.dateValue.start,
      });
    } catch (error) {
      const message =
        error && error.error && error.error.message
          ? error.error.message
          : "error";
      this._toasterService.showInfo(message);
    } finally {
      this.stopLoading();
    }
  }

  private async getEventsForProduct({
    productId,
    date,
  }: {
    productId: number;
    date: moment.Moment;
  }): Promise<Event[]> {
    return this._getDayEvents(productId, date);
  }

  private getProductsForCategory(categoryId: string): Product[] {
    return this.products.filter((product) =>
      product.categoryList.some(
        (productCategory) => `${productCategory.id}` === categoryId
      )
    );
  }

  private startLoading(): void {
    this.reloadingEvents = true;
    this._globalService.loading = this.reloadingEvents;
  }

  private stopLoading(): void {
    this.reloadingEvents = false;
    this._globalService.loading = this.reloadingEvents;
    this._updateGlobalService();
  }

  public updateDaysWithEvents(date?: moment.Moment): void {
    this.daysWithEventsPromise = new Promise<moment.Moment>(async (resolve) => {
      this._globalService.loading = true;

      let events: Event[];
      if (this.selectedProduct) {
        // if a product is selected
        events = await this._getMonthEvents(this.selectedProduct.id, date);
      } else if (this.selectedCategory) {
        // if a category is selected
        // Get all products that contain the selected category in the categoryList
        const categoryProducts = this.products.filter((product) =>
          product.categoryList.some(
            (productCategory) => productCategory.id === this.selectedCategory.id
          )
        );
        events = [];
        for (const product of categoryProducts) {
          events.push(...(await this._getMonthEvents(product.id, date)));
        }
      }

      // if there are events for the month of 'date' populate 'daysWithEvents' with unique date values.
      const daysWithEvents = [];
      if (events) {
        const map = new Map();
        for (const event of events) {
          if (!map.has(event.start)) {
            map.set(event.start, true);
            daysWithEvents.push({ date: moment(event.start) });
          }
        }
      }
      this.daysWithEvents = daysWithEvents;

      this._globalService.loading = false;
      resolve(date);
    });
  }

  public async onDateChange(event: {
    start: moment.Moment;
    end: moment.Moment;
  }): Promise<void> {
    if (
      this.dateValue.start !== event.start ||
      this.dateValue.end !== event.end
    ) {
      this.dateValue = event;
      await this.reloadEvents();
    }
  }

  private _getMonthEvents(
    productId: number,
    monthDate: moment.Moment
  ): Promise<Event[]> {
    monthDate = monthDate.clone();
    const from = monthDate.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
    const to = monthDate.endOf("month").format("YYYY-MM-DD") + " 23:59:59";
    return this._widgetService
      .getEvents(productId.toString(10), from, to)
      .toPromise();
  }

  public _getDayEvents(
    productId: number,
    date: moment.Moment
  ): Promise<Event[]> {
    date = date.clone();
    const from = date.format("YYYY-MM-DD") + " 00:00:00";
    const to = date.format("YYYY-MM-DD") + " 23:59:59";
    return this._widgetService
      .getEvents(productId.toString(10), from, to)
      .toPromise();
  }

  private _resetEvents(): void {
    this.productEvents = undefined;
  }

  private _updateGlobalService(): void {
    if (this.selectedProduct) {
      this._globalService.productId = this.selectedProduct.id;
      this._globalService.product = this.selectedProduct;
    }
  }

  private _productBelongsToSelectedCategory(product: Product): boolean {
    // noinspection TypeScriptValidateTypes
    return (
      this.selectedCategory &&
      product.categoryList.find(
        (category) => category.id === this.selectedCategory.id
      ) !== undefined
    );
  }

  private _showInfo(propertyName: string, product: Product): boolean {
    const prop = "isWidgetToShow" + propertyName;
    let result = this.selectedProduct ? this.selectedProduct[prop] : false;
    if (
      this.selectedCategory &&
      this._productBelongsToSelectedCategory(product)
    ) {
      result = this.selectedCategory.settings[prop];
    }
    return result;
  }

  public getEventResourcesDescription(event: Event): string {
    return event.eventResources
      ? event.eventResources.map((resource) => resource.name).join(", ")
      : "";
  }

  public getEventAvailabilityDescription(event: Event): string {
    let result;
    switch (event.quantityAvailable) {
      case 0:
        result = "Occupied";
        break;
      case -1:
        result = "Unlimited";
        break;
      default:
        result = event.quantityAvailable + " People";
    }
    return result;
  }

  public showActivityName(product: Product): boolean {
    return this._showInfo("ActivityName", product);
  }

  public showGroupScheduleTitle(product: Product): boolean {
    return this._showInfo("GroupScheduleTitle", product);
  }

  public showResourceName(product: Product): boolean {
    return this._showInfo("ResourceName", product);
  }

  public showDuration(product: Product): boolean {
    return this._showInfo("Duration", product);
  }

  public showPriceRange(product: Product): boolean {
    // ** product.isWidgetToShowPriceRange */
    return this._showInfo("PriceRange", product);
  }

  public showTotalAvailability(product: Product): boolean {
    return this._showInfo("TotalAvailability", product);
  }

  public showCategoriesAndProducts(): boolean {
    return (
      this.categoriesAndProductsOptions &&
      this.categoriesAndProductsOptions.length > 0
    );
  }

  public showNoProductsMessage(): boolean {
    return (
      this.categoriesAndProductsOptions &&
      this.categoriesAndProductsOptions.length === 0
    );
  }

  public showNoEventsMessage(): boolean {
    return !this.reloadingEvents && this.events && this.events.length === 0;
  }

  public getCategoriesAndProductsLabel(): string {
    return this.categoriesAndProductsOptions &&
      this.categoriesAndProductsOptions.length === 1 &&
      this.categoriesAndProductsOptions[0].type === "product"
      ? this.categoriesAndProductsOptions[0].label
      : "What would you like to do?";
  }

  public async handleCategoryOrProductChange(
    categoryOrProduct: any,
    type: string
  ): Promise<void> {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    this.productsOptions = undefined;
    this._resetEvents();
    if (type === "category") {
      this.selectedProduct = undefined;
      // await until the dom has acknowledged modifications to component's properties
      await sleep(1);
      await this.handleCategoryChange(categoryOrProduct);
      await this.reloadEvents();
    } else {
      this.selectedCategory = undefined;
      // await until the dom has acknowledged modifications to component's properties
      await sleep(1);
      this.handleProductChange(categoryOrProduct);
      await this.reloadEvents();
    }
  }

  public async handleCategoryChange(category: Category): Promise<void> {
    if (this.selectedCategory !== category) {
      this.selectedCategory = category;
    }
  }

  public handleProductChange(product: Product): void {
    if (this.selectedProduct !== product) {
      this.selectedProduct = product;
      this._resetEvents();
      this._updateGlobalService();
    }
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date || !this.selectedProduct;
  }

  public eventDate(): string {
    return this._globalService.event_date;
  }

  public eventLowerPrice(event): any {
    const eventPrincesGross = event.eventGenderPrices.map(
      (genderPrice) => genderPrice.grossprice
    ); // array of gross prices
    // const lowerPrice = Math.min(...eventPrincesGross);
    const lowerPrice = Math.min.apply(Math, eventPrincesGross.filter(Number)); // will avoid zero (0) / Boolean
    // console.log('eventPrincesGross, lowerPrice :>> ', eventPrincesGross, lowerPrice);
    return lowerPrice + " €";
  }
  public eventHigherPrice(event): any {
    const eventPrincesGross = event.eventGenderPrices.map(
      (genderPrice) => genderPrice.grossprice
    ); // array of gross prices
    const higherPrice = Math.max(...eventPrincesGross);
    // console.log('eventPrincesGross, higherPrice :>> ', eventPrincesGross, higherPrice);
    return higherPrice + " €";
  }

  public eventName(event: any) {
    return event.nameAlias != null && event.nameAlias != ""
      ? event.nameAlias
      : event.product.name;
  }

  public isButtonDisabled(event: any): boolean {
    return !(event.product.isSellableTillEnd
      ? moment().isBefore(moment(event.end))
      : moment().isBefore(moment(event.start)));
  }
}
