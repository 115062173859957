import { Component, OnInit } from "@angular/core";
import { GenderPrice } from "../shared/api/model/gender-price";
import { GlobalService } from "../shared/global.service";
import { Booking } from "../shared/api/model/booking";
import { Subscription } from "rxjs";
import { WidgetService } from "../shared/api/widget/widget.service";

import { Product } from "../shared/api/model/product";
import { Event } from "../shared/api/model/event";
import { ActivatedRoute, Router } from "@angular/router";
import { BookingCalculation } from "../shared/api/model/booking-calculation";
import { StaticUtils } from "../shared/utils/static-utils";
import { CartService } from "../shared/cart/cart.service";
import { ToasterService } from "../shared/atoms/toaster/toaster.service";
import * as moment from "moment";

@Component({
  selector: "app-pax",
  templateUrl: "./pax.component.html",
  styleUrls: ["./pax.component.scss"],
})
export class PaxComponent implements OnInit {
  utils = StaticUtils;
  eventGenderPrices: GenderPrice[];
  calculatedPriceSub: Subscription;
  calculatingPrice = false;
  product: Product;
  event: Event;
  _reachedLimitOfAvailability: boolean = false;
  _sumOfGenderPriceQuantityToBook: number | null = null;
  _newQuantityAvailable: number | null = null;
  moment = moment;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService,
    private _widgetService: WidgetService,
    private _toasterService: ToasterService,
    private _cartService: CartService
  ) {}

  ngOnInit() {
    this.event = this._globalService.event;
    this.product = this._globalService.product;
    this.eventGenderPrices = this._globalService.eventGenderPrices;

    for (let i = 0; i < this.eventGenderPrices.length; i++) {
      if (this.eventGenderPrices[i].audienceTypeOccupancy >= 2) {
        this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
          Math.floor(
            this.event.maximumLotation /
              this.eventGenderPrices[i].audienceTypeOccupancy
          );
      } else {
        this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
          this.event.maximumLotation; // start with event.maximumLotation. Will be changed later.
      }
    }
    console.log("this.eventGenderPrices :>> ", this.eventGenderPrices);

    this._newQuantityAvailable = this.event.quantityAvailable - this._globalService.totalPax();

    if (this._globalService.booking) {
      this._globalService.booking.bookingComponents = undefined;
      this.updatePrice();
    }
  }

  public async goToSummary(): Promise<void> {
    if (
      (this.event.widgetHaveAccessExtras && this._globalService.hasExtras()) ||
      (this.event.widgetHaveAccessPickups && this._globalService.hasTransport())
    ) {
      await this._router.navigate(["../add/"], {
        relativeTo: this._route,
        queryParamsHandling: "merge",
      });
      this._globalService.selectedTransportComponent = undefined
    } else if (this.event.bookingQuestionIsActive) {
      await this._router.navigate(["../question/"], {
        relativeTo: this._route,
        queryParamsHandling: "merge",
      });
    } else {
      try {
        this._globalService.loading = true;
        await this._cartService.addBookingToCart(this.booking);
        this._globalService.clearBookingProductAndEvent();
        (await this._globalService.skip_my_cart) === true
          ? this._router.navigate(["../summary/"], {
              relativeTo: this._route,
              queryParamsHandling: "merge",
            })
          : this._router.navigate(["../cart/"], {
              relativeTo: this._route,
              queryParamsHandling: "merge",
            });
      } catch (e) {
        this._toasterService.showError(e);
      } finally {
        this._globalService.loading = false;
      }
    }
  }

  public updateQuantity(index: number, value: number): void {
    // console.log('index, value, e :>> ', index, value, e);
    if (this.calculatedPriceSub) {
      this.calculatedPriceSub.unsubscribe();
    }
    if (this.isGenderPriceVisible(this.eventGenderPrices[index])) {
      if (this.eventGenderPrices[index]) {
        this.eventGenderPrices[index].quantityToBook = value;

        this._newQuantityAvailable =
          this.event.quantityAvailable - this._globalService.totalPax();
        console.log(
          "this._newQuantityAvailable :>> ",
          this._newQuantityAvailable
        );

        // this.eventGenderPrices[index].maxAvailabilityForCurrentAudienceType = this._newQuantityAvailable - value;
        for (let i = 0; i < this.eventGenderPrices.length; i++) {
          if (i !== index) {
            if (this.eventGenderPrices[i].audienceTypeOccupancy >= 2) {
              // 17 / 2
              this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
                Math.floor(
                  this._newQuantityAvailable /
                    this.eventGenderPrices[i].audienceTypeOccupancy
                ) + this.eventGenderPrices[i].quantityToBook;
            } else {
              this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
                this._newQuantityAvailable +
                this.eventGenderPrices[i].quantityToBook;
            }
          }
        }
      }

      console.log(
        "this.eventGenderPrices :>> ",
        this.eventGenderPrices.map((gp: any) => {
          return "max: " + gp.maxAvailabilityForCurrentAudienceType;
        })
      );

      this._globalService.eventGenderPrices = this.eventGenderPrices;

      const booking = new Booking();
      booking.bookingGenderPrices = this.eventGenderPrices;
      booking.event = this._globalService.event;
      this._globalService.booking = booking;
      this.updatePrice();
    }
  }

  public updatePrice(): void {
    this.calculatingPrice = true;
    this.calculatedPriceSub = this._widgetService
      .calculatePrice(this.booking)
      .subscribe(
        (bookingCalculation: BookingCalculation) => {
          this._globalService.calculatedPrice = bookingCalculation;
          if (this._globalService.booking) {
            this._globalService.booking.calculationsGUID =
              bookingCalculation.calculationsGUID;
          }
        },
        undefined,
        () => (this.calculatingPrice = false)
      );
  }

  public getGenderPriceLabel(genderPrice: GenderPrice): string {
    return genderPrice.audienceTypeDescription || "Tickets";
  }

  public isGenderPriceVisible(genderPrice: GenderPrice): boolean {
    return (
      genderPrice.genderPriceType !== 1 ||
      (genderPrice.genderPriceType === 1 && genderPrice.isExcludedFromBilling)
    );
  }

  public hasAvailableQuantity(event: Event, atLeastOne = false): boolean {
    return (
      this.isUnlimited(event) ||
      (atLeastOne
        ? this.totalQuantity < event.quantityAvailable
        : this.totalQuantity <= event.quantityAvailable)
    );
  }

  public isUnlimited(event: Event): boolean {
    return (
      event.availabilityType === 1 ||
      event.productAvailabilityTypeDescription === "Unlimited" ||
      event.hasLotation === false ||
      event.quantityAvailable === -1 ||
      event.maximumLotation === -1
    );
  }

  public reachedLimitOfAvailability = (
    quantityAvailable: number,
    eventGenderPrices: any,
    i: number
  ): boolean => {
    /**
     * Check if quantity To Book has reached the quantity of Availability
     * @boolean
     */
    this._sumOfGenderPriceQuantityToBook = eventGenderPrices
      .filter((gp: any) => gp.audienceType !== null) // discard those without audience type defined
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: any, next: any) => {
        return prev + next; // sum of all quantity to book values in the array
      });

    if (
      quantityAvailable === this._sumOfGenderPriceQuantityToBook ||
      eventGenderPrices[i].audienceTypeOccupancy > this._newQuantityAvailable
    ) {
      this._reachedLimitOfAvailability = true;
    } else {
      this._reachedLimitOfAvailability = false;
    }

    // console.log('quantityAvailable, this._sumOfGenderPriceQuantityToBook, eventGenderPrices :>> ', quantityAvailable, this._sumOfGenderPriceQuantityToBook, eventGenderPrices);
    // console.log('this._reachedLimitOfAvailability :>> ', this._reachedLimitOfAvailability);

    return this._reachedLimitOfAvailability;
  };

  get booking(): Booking {
    return this._globalService.booking;
  }

  get totalPrice(): string {
    return StaticUtils.convertValueToCurrency(
      this._globalService.calculatedPrice
        ? this._globalService.calculatedPrice.grandTotal
        : 0
    );
  }

  get totalQuantity(): number {
    let result = 0;
    // this._newQuantityAvailable = this.event.quantityAvailable - this._globalService.totalPax()
    // const quantityAvailable = this.event.quantityAvailable;
    for (const eventGenderPrice of this.eventGenderPrices) {
      if (this.isGenderPriceVisible(eventGenderPrice)) {
        if (eventGenderPrice.audienceTypeOccupancy >= 2) {
          result +=
            eventGenderPrice.audienceTypeOccupancy *
            eventGenderPrice.quantityToBook;
        } else {
          result += eventGenderPrice.quantityToBook;
        }
      }
    }
    // console.log('----------------------------------------------------------------------');
    // console.log('this.eventGenderPrices :>> ', this.eventGenderPrices.map((gp:any)=>{return "max: " + gp.maxAvailabilityForCurrentAudienceType }));
    // console.log('this.event.quantityAvailable :>> ', this.event.quantityAvailable);
    // console.log('this._newQuantityAvailable :>> ', this._newQuantityAvailable);
    // console.log('this._globalService.totalPax() :>> ', this._globalService.totalPax());
    // console.log('totalQuantity :>> ', result);
    return result;
  }

  // public totalPax(): number {
  //   let totalPax = 0;

  //   for (let personPrice of this.event.eventGenderPrices) {
  //     console.log(
  //       "personPrice.quantityToBook :>> ",
  //       personPrice.quantityToBook
  //     );
  //     // If is price per person (genderPrice = 2)
  //     if (personPrice.genderPriceType == 2) {
  //       // let quantityPerTicket = audience.occupancy? audience.occupancy : 1 // for example: "Group of 3" will have 3, else just use 1
  //       let quantityPerTicket = personPrice.audienceTypeOccupancy
  //         ? personPrice.audienceTypeOccupancy
  //         : 1;
  //       totalPax += personPrice.quantityToBook * Number(quantityPerTicket);
  //     } else {
  //       // is price per order WITH participants
  //       if (
  //         personPrice.genderPriceType == 1 &&
  //         this.event.eventGenderPrices.length > 1
  //       ) {
  //         totalPax +=
  //           personPrice.audienceType != null ? personPrice.quantityToBook : 0;
  //       }

  //       // is price per order WITHOUT participants
  //       if (
  //         personPrice.genderPriceType == 1 &&
  //         this.event.eventGenderPrices.length == 1
  //       ) {
  //         totalPax += 1;
  //       }
  //     }
  //   }

  //   return totalPax;
  // }

  get newQuantityAvailable(): number {
    return this._newQuantityAvailable;
  }

  get sumOfGenderPriceQuantityToBook(): number {
    return this._sumOfGenderPriceQuantityToBook;
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date;
  }
}
