import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-small',
  templateUrl: './loading-small.component.html',
  styleUrls: ['./loading-small.component.scss']
})
export class LoadingSmallComponent implements OnInit {
  @Input('background') background = 'transparent';
  style = {};
  constructor() { }

  ngOnInit() {
    this.style = {
      'background-color': this.background
    };
  }

}
