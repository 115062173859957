import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {Booking} from '../model/booking';
import {GlobalService} from '../../global.service';
import {Customer} from '../model/customer';
import {BillingDetails} from '../model/billing-details';

@Injectable()
export class WidgetService {
  private _resourceServer = environment.resourceServer;

  constructor(private _http: HttpClient, private _globalService: GlobalService) { }

  getStripeKey(): Observable<any> {
    return this._http.get(this._resourceServer + 'widget/SApiKey?guid=' + this._globalService.guid);
  }

  getInfo(): Observable<any> {
    return this._http.get(this._resourceServer + 'widget/WInfo?guid=' + this._globalService.guid);
  }

  getEvents(productId: string, dtFrom: string,  dtTo: string): Observable<any> {
    return this._http.get(this._resourceServer + 'widget/SEvents?guid=' + this._globalService.guid
      + '&productId=' + productId
      + '&dtStart=' + dtFrom
      + '&dtEnd=' + dtTo);
  }

  /**
   *  @Deprecated
   */
  createBooking(stripeToken: string, booking: Booking): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SBook',
        {
          guid: this._globalService.guid,
          stripeToken: stripeToken,
          booking: booking
        }
      );
  }

  /**
   *  @Deprecated
   *  Use shoppingCartStripePaymentIntent instead
   */
  bookStripePaymentIntent(booking: Booking): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SBookStripePaymentIntent',
      {
        guid: this._globalService.guid,
        booking: booking
      }
    );
  }

  bookStripePaymentSuccess(updatedPaymentIntentResult: any): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SBookStripePaymentSuccess', updatedPaymentIntentResult);
  }

  bookStripePaymentError(updatedPaymentIntentResult: any): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SBookStripePaymentError', updatedPaymentIntentResult);
  }

  downloadTicket(): Observable<any> {
    return this._http.get(
      this._resourceServer + 'widget/DownloadTicket?guid=' + this._globalService.guid + '&tguid=' + this._globalService.tguid);
  }

  calculatePrice(booking: Booking) {
    return this._http.post(this._resourceServer + 'widget/CalculatePrice',
      {
        guid: this._globalService.guid,
        booking: booking
      }
    );
  }

  findPromocode(supplierId: string | number, promocode: string): Observable<any> {
    return this._http.get(
      this._resourceServer + 'supplier/WidgetFindPromocode?supplierId=' + supplierId + '&promocode=' + promocode);
  }

  addBookingToShoppingCart(booking: Booking, id: number = null, guid: string = null): Observable<any> {
    booking.bookingShoppingCartId = id;
    return this._http.post(this._resourceServer + 'widget/SAddBookingToShoppingCart',
      {
        WidgetGuid: this._globalService.guid,
        ShoppingCartId: id || 0,
        ShoppingCartGuid: guid,
        booking: booking
      }
    );
  }

  deleteBookingFromShoppingCart(booking: Booking, id: number, guid: string): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SDeleteBookingFromShoppingCart',
      {
        WidgetGuid: this._globalService.guid,
        ShoppingCartId: id,
        ShoppingCartGuid: guid,
        booking: booking
      }
    );
  }

  resumeCheckout(shoppingCartId: string | number, shoppingCartGuid: string | number): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SResumeCheckout',
      {
        WidgetGuid: this._globalService.guid,
        ShoppingCartId: shoppingCartId,
        ShoppingCartGuid: shoppingCartGuid,
      }
    );
  }

  applyPromocodeToShoppingCart(shoppingCartId: string | number, shoppingCartGuid: string | number, promocode: string): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SApplyPromocodeToShoppingCart',
      {
        WidgetGuid: this._globalService.guid,
        ShoppingCartId: shoppingCartId,
        ShoppingCartGuid: shoppingCartGuid,
        Promocode: promocode,
      }
    );
  }

  addCustomerToShoppingCart(shoppingCartId: string | number, shoppingCartGuid: string | number, customer: Customer, billing = new BillingDetails()): Observable<any> {
    return this._http.post(this._resourceServer + 'widget/SAddCustomerToShoppingCart',
      {
        WidgetGuid: this._globalService.guid,
        ShoppingCartId: shoppingCartId,
        ShoppingCartGuid: shoppingCartGuid,
        customer: customer,
        billing: {
          details: billing
        }
      }
    );
  }

  shoppingCartStripePaymentIntent(shoppingCartId: string | number, shoppingCartGuid: string | number) {
    return this._http.post(this._resourceServer + 'widget/SShoppingCartStripePaymentIntent',
      {
        WidgetGuid: this._globalService.guid,
        ShoppingCartId: shoppingCartId,
        ShoppingCartGuid: shoppingCartGuid,
      }
    );
  }
}
