import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CartService} from '../shared/cart/cart.service';
import {StaticUtils} from '../shared/utils/static-utils';
import * as moment from 'moment';
import {GlobalService} from '../shared/global.service';
import {Booking} from '../shared/api/model/booking';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  cart: { bookings: Booking[] };
  bookingToBeRemoved: Booking;
  utils = StaticUtils;
  moment = moment;
  shoppingCartChangeSub: Subscription;

  constructor(private _router: Router, private _globalService: GlobalService, private _cartService: CartService) { }

  ngOnInit(): void {
    setTimeout(() => this._updateCart(true));
    this.shoppingCartChangeSub = this._cartService.shoppingCartChange.subscribe(() => this._updateCart());
  }

  ngOnDestroy(): void {
    this.shoppingCartChangeSub.unsubscribe();
  }

  private async _updateCart(forceUpdate = false): Promise<void> {
    this._globalService.loading = true;
    this.cart = await this._cartService.getCart(forceUpdate);
    this._globalService.loading = false;
  }

  removeBooking(): void {
    this._globalService.loading = true;
    this._cartService.removeBookingFromCart(this.bookingToBeRemoved).then(
      () => this._globalService.loading = false,
      () => this._globalService.loading = false
    );
    this.bookingToBeRemoved = undefined;
  }

  goToHome(): void {
    this._router.navigate(['home'], {queryParamsHandling: 'merge'}).then();
  }

  goToPayment(): void {
    this._router.navigate(['summary'], {queryParamsHandling: 'merge'}).then();
  }

  canGoToPayment(): boolean {
    return this.cart && this.cart.bookings && this.cart.bookings.length > 0 ? !this.cart.bookings.some(booking => booking.status === -50) : false;
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date;
  }

}
