import {Component, OnInit, ViewChild} from '@angular/core';
import {GlobalService} from '../shared/global.service';
import {Event} from '../shared/api/model/event';
import {NgbAccordion} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {Route} from '../shared/api/model/route';
import {RouteItinerary} from '../shared/api/model/route-itinerary';
import {Transport} from '../shared/api/model/transport';
import {TransportIteraction} from '../shared/api/model/transport-iteraction';
import {RouteSchedule} from '../shared/api/model/route-schedule';
import {StaticUtils} from '../shared/utils/static-utils';
import {Location} from '../shared/api/model/location';
import * as moment from 'moment';
import {ToasterService} from '../shared/atoms/toaster/toaster.service';
import {CartService} from '../shared/cart/cart.service';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {
  @ViewChild('accordion', {static: false}) accordion: NgbAccordion;

  event: Event;
  routes = [];

  pickupList = [];
  dropoffList = [];

  pickupItenerariesList = [];
  dropoffItenerariesList = [];

  pickupSchedulesList = [];
  dropoffSchedulesList = [];

  pickupCustomAddressToggle = false;
  dropoffCustomAddressToggle = false;

  transport: Transport;
  pickupIteraction = new TransportIteraction();
  dropoffIteraction = new TransportIteraction();

  utils = StaticUtils;
  moment = moment;

  private _selectedPickupRoute: Route;
  private _selectedDropoffRoute: Route;

  private _selectedPickupItenerary: RouteItinerary;
  private _selectedDropoffItenerary: RouteItinerary;

  private _selectedPickupSchedule: RouteSchedule;
  private _selectedPickupScheduleDate = StaticUtils.formatDateForBackend(moment());
  private _selectedDropoffSchedule: RouteSchedule;
  private _selectedDropoffScheduleDate = StaticUtils.formatDateForBackend(moment());

  private _customPickupFrom = '';
  private _customPickupTo = '';
  private _customPickupDate = StaticUtils.formatDateForBackend(moment());
  private _customPickupTime = '12:00';

  private _customDropoffFrom = '';
  private _customDropoffTo = '';
  private _customDropoffDate = StaticUtils.formatDateForBackend(moment());
  private _customDropoffTime = '12:00';

  constructor(
    private _router: Router,
    public _globalService: GlobalService,
    private _toasterService: ToasterService,
    private _cartService: CartService) { }

  ngOnInit() {
    this.event = this._globalService.event;
    this.routes = this.event.eventRoutes;
    this._customPickupTime = this.event.start.split(' ')[1].split(':').splice(0, 2).join(':');
    this._customDropoffTime = this.event.end.split(' ')[1].split(':').splice(0, 2).join(':');
    this._customDropoffFrom = this._customPickupTo = this.event.location;

    this.initPickupAndDropoffLists();
    this.initPickupAndDropoffItinerariesList();

    if(this._globalService.isMoreThanOneTransportComponent) {
      this.pickupCustomAddressToggle = true;
      this.dropoffCustomAddressToggle = true;
    }

    this.transport = this._globalService.booking.transport ? this._globalService.booking.transport : new Transport();
    this.initSelection();

    this.initPickupandDropoffSchedulesList();
    this.onModelChange();
  }

  initSelection() {
    this._selectedPickupScheduleDate = this.event.start;
    this._selectedDropoffScheduleDate = this.event.start;
    this._customPickupDate = this.event.start;
    this._customDropoffDate = this.event.start;

    if (this._globalService.selectedBookingTransportPickupItenerary) {
      this._selectedPickupItenerary = this._globalService.selectedBookingTransportPickupItenerary;
      this.selectedPickupRoute = this.getPickupIteneraryRoute(this._selectedPickupItenerary);
    }

    if (this._globalService.selectedBookingTransportDropoffItenerary) {
      this._selectedDropoffItenerary = this._globalService.selectedBookingTransportDropoffItenerary;
      this.selectedDropoffRoute = this.getDropoffIteneraryRoute(this._selectedDropoffItenerary);
    }

    // Init from transport object
    if (this.transport.transfers) {
      for (const transfer of this.transport.transfers) {
        if ((transfer.route && transfer.route.type === 1) || (transfer.isCustom && transfer.transportDirection === 1)) {
          this.pickupIteraction = transfer;
          if (!this.pickupIteraction.isCustom) {
            if (this.pickupIteraction.route && !this._selectedPickupRoute) {
              this._selectedPickupRoute = this.pickupIteraction.route;
            }
            if (this.pickupIteraction.stop && !this._selectedPickupItenerary) {
              this._selectedPickupItenerary = new RouteItinerary();
              this._selectedPickupItenerary.stop = this.pickupIteraction.stop;
            }
            if (this.pickupIteraction.schedule) {
              this._selectedPickupSchedule = this.pickupIteraction.schedule;
              this._selectedPickupScheduleDate = this.pickupIteraction.schedule.date;
            } else {
              this._selectedPickupSchedule = new RouteSchedule();
            }
          } else {
            this.pickupCustomAddressToggle = true;
            if (this.pickupIteraction.stop) {
              this._customPickupFrom = this.pickupIteraction.stop.name;
            }
            if (this.pickupIteraction.exitStop) {
              this._customPickupTo = this.pickupIteraction.exitStop.name;
            }
            if (this.pickupIteraction.schedule) {
              this._customPickupDate = this.pickupIteraction.schedule.date;
              this._customPickupTime = this.pickupIteraction.schedule.time;
            }
          }
        }
        if ((transfer.route && transfer.route.type === 2) || (transfer.isCustom && transfer.transportDirection === 2)) {
          this.dropoffIteraction = transfer;
          if (!this.dropoffIteraction.isCustom) {
            this.dropoffIteraction = transfer;
            if (this.dropoffIteraction.route && !this._selectedDropoffRoute) {
              this._selectedDropoffRoute = this.dropoffIteraction.route;
            }
            if (this.dropoffIteraction.stop && !this._selectedDropoffItenerary) {
              this._selectedDropoffItenerary = new RouteItinerary();
              this._selectedDropoffItenerary.stop = this.dropoffIteraction.stop;
            }
            if (this.dropoffIteraction.schedule) {
              this._selectedDropoffSchedule = this.dropoffIteraction.schedule;
              this._selectedDropoffScheduleDate = this.dropoffIteraction.date;
            } else {
              this._selectedDropoffSchedule = new RouteSchedule();
            }
          } else {
            this.dropoffCustomAddressToggle = true;
            if (this.dropoffIteraction.stop) {
              this._customDropoffFrom = this.dropoffIteraction.stop.name;
            }
            if (this.dropoffIteraction.exitStop) {
              this._customDropoffTo = this.dropoffIteraction.exitStop.name;
            }
            if (this.dropoffIteraction.schedule) {
              this._customDropoffDate = this.dropoffIteraction.schedule.date;
              this._customDropoffTime = this.dropoffIteraction.schedule.time;
            }
          }
        }
      }
    } else {
      this.transport.transfers = [];
    }
  }

  initPickupAndDropoffLists() {
    for (const route of this.routes) {
      if (route.type === 1) {
        this.pickupList.push({label: route.name, value: route});
      } else if (route.type === 2) {
        this.dropoffList.push({label: route.name, value: route});
      }
    }
  }

  isOnlyOnePickupOrOneDropoff() {
    return this.pickupItenerariesList.length > 0 && this.dropoffItenerariesList.length === 0
      || this.pickupItenerariesList.length === 0 && this.dropoffItenerariesList.length > 0;
  }

  isToShowReturnToText() {
    if(this._globalService.isMoreThanOneTransportComponent && this.dropoffItenerariesList.length === 0 || this._globalService.isMoreThanOneTransportComponent && this.dropoffItenerariesList.length > 0) {
      return true;
    }
    return false;
  }

  isToShowPickupAtText() {
    if(this._globalService.isMoreThanOneTransportComponent && this.pickupItenerariesList.length === 0 || this._globalService.isMoreThanOneTransportComponent && this.pickupItenerariesList.length > 0) {
      return true;
    }
    return false;
  }

  foundPickupOrDropoff(): boolean {
    return this.pickupItenerariesList.length > 0 || this.dropoffItenerariesList.length > 0;
  }

  // pickupPlaceholderText() {
  //   if(this.isOnlyOnePickupOrOneDropoff()) {
  //     return 'Select an address';
  //   } else {
  //     return 'Select a pickup point';
  //   }
  // }
  // dropoffPlaceholderText() {
  //   if(this.isOnlyOnePickupOrOneDropoff()) {
  //     return 'Select an address';
  //   } else {
  //     return 'Select a dropoff point';
  //   }
  // }

  initPickupAndDropoffItinerariesList() {
    for (const pickup of this.pickupList) {
      if (pickup.value.iteneraries) {
        for (const itinerary of pickup.value.iteneraries) {
          this.pickupItenerariesList.push({label: itinerary.stop.name, value: itinerary});
        }
      }
    }
    for (const dropoff of this.dropoffList) {
      if (dropoff.value.iteneraries) {
        for (const itinerary of dropoff.value.iteneraries) {
          this.dropoffItenerariesList.push({label: itinerary.stop.name, value: itinerary});
        }
      }
    }
  }

  initPickupandDropoffSchedulesList() {
    this.initPickupSchedulesList();
    this.initDropoffSchedulesList();
  }

  initPickupSchedulesList() {
    this.pickupSchedulesList = [];
    if (this._selectedPickupItenerary && this._selectedPickupItenerary.schedules) {
      for (const schedule of this._selectedPickupItenerary.schedules) {
        this.pickupSchedulesList.push({label: schedule.timeShort , value: schedule});
      }
    }
  }

  initDropoffSchedulesList() {
    this.dropoffSchedulesList = [];

    const dropoffDestinationItinerary = this.getDropoffDestinationItinerary();
    if (dropoffDestinationItinerary && dropoffDestinationItinerary.schedules) {
      for (const schedule of dropoffDestinationItinerary.schedules) {
        this.dropoffSchedulesList.push({label: schedule.timeShort , value: schedule});
      }
      if (this.dropoffSchedulesList.length > 0) {
        this._selectedDropoffSchedule = this.dropoffSchedulesList[0].value;
      }
    }
  }

  onModelChange() {
    this.pickupIteraction.isCustom = this.pickupCustomAddressToggle;
    if (!this.pickupIteraction.isCustom) {
      if (this._selectedPickupRoute) {
        this.pickupIteraction.route = this._selectedPickupRoute;
        this.pickupIteraction.exitStop = this._selectedPickupRoute.routeDestination;
      } else {
        this.pickupIteraction.route = undefined;
        this.pickupIteraction.exitStop = undefined;
      }

      if (this._selectedPickupItenerary && this._selectedPickupItenerary.stop) {
        this.pickupIteraction.stop = this._selectedPickupItenerary.stop;
      } else {
        this.pickupIteraction.stop = undefined;
      }

      this.pickupIteraction.schedule = this._selectedPickupSchedule ? this._selectedPickupSchedule : new RouteSchedule();
      this.pickupIteraction.schedule.date = this._selectedPickupScheduleDate;

    } else {
      this.pickupIteraction.route = undefined;
      this.pickupIteraction.order = 0;
      this.pickupIteraction.stop = new Location();
      this.pickupIteraction.stop.name = this._customPickupFrom;
      this.pickupIteraction.exitStop = new Location();
      this.pickupIteraction.exitStop.name = this._customPickupTo && this._customPickupTo !== '' ? this._customPickupTo : 'Activity location';
      this.pickupIteraction.schedule = new RouteSchedule();
      this.pickupIteraction.schedule.date = this._customPickupDate;
      this.pickupIteraction.schedule.time = this._customPickupTime;
    }

    this.dropoffIteraction.isCustom = this.dropoffCustomAddressToggle;
    if (!this.dropoffIteraction.isCustom) {
      if (this._selectedDropoffRoute) {
        this.dropoffIteraction.route = this._selectedDropoffRoute;
        this.dropoffIteraction.exitStop = this.getDropoffDestinationItinerary().stop;
      } else {
        this.dropoffIteraction.route = undefined;
        this.dropoffIteraction.exitStop = undefined;
      }

      if (this._selectedDropoffItenerary && this._selectedDropoffItenerary.stop) {
        this.dropoffIteraction.stop = this._selectedDropoffItenerary.stop;
      } else {
        this.dropoffIteraction.stop = undefined;
      }

      this.dropoffIteraction.schedule = this._selectedDropoffSchedule ? this._selectedDropoffSchedule : new RouteSchedule();
      this.dropoffIteraction.schedule.date = this._selectedDropoffScheduleDate;

    } else {
      this.dropoffIteraction.route = undefined;
      this.dropoffIteraction.order = 0;
      this.dropoffIteraction.stop = new Location();
      this.dropoffIteraction.stop.name = this._customDropoffFrom && this._customDropoffFrom !== '' ? this._customDropoffFrom : 'Activity location';
      this.dropoffIteraction.exitStop = new Location();
      this.dropoffIteraction.exitStop.name = this._customDropoffTo;
      this.dropoffIteraction.schedule = new RouteSchedule();
      this.dropoffIteraction.schedule.date = this._customDropoffDate;
      this.dropoffIteraction.schedule.time = this._customDropoffTime;
    }

    this.transport.transfers = [];
    if (this.pickupIteraction.stop && this.pickupIteraction.stop.name !== '') {
      this.pickupIteraction.transportDirection = 1;
      this.transport.transfers.push(this.pickupIteraction);
    }
    if (this.dropoffIteraction.exitStop && this.dropoffIteraction.exitStop.name !== '') {
      this.dropoffIteraction.transportDirection = 2;
      this.transport.transfers.push(this.dropoffIteraction);
    }
  }

  async goToSummaryOrQuestion() {
    this._globalService.booking.transport = this.transport;
    this._globalService.selectedBookingTransportPickupItenerary = this._selectedPickupItenerary;
    this._globalService.selectedBookingTransportDropoffItenerary = this._selectedDropoffItenerary;

    if (this.event.bookingQuestionIsActive) {
      await this._router.navigate(['question'], { queryParamsHandling: 'merge' });
    } else {
      try {
        this._globalService.loading = true;
        await this._cartService.addBookingToCart(this._globalService.booking);
        this._globalService.clearBookingProductAndEvent();
        // await this._router.navigate(['cart'], { queryParamsHandling: 'merge' });
        await this._globalService.skip_my_cart === true ? this._router.navigate(['summary'], { queryParamsHandling: 'merge' }) : this._router.navigate(['cart'], { queryParamsHandling: 'merge' });
      } catch (e) {
        this._toasterService.showError(e);
      } finally {
        this._globalService.loading = false;
      }
    }
  }

  togglePickupCustomAddress() {
    this.pickupCustomAddressToggle = !this.pickupCustomAddressToggle;
    if (!this.pickupCustomAddressToggle) {
      this.initPickupSchedulesList();
    } else {
      this.pickupSchedulesList = [];
    }
    this.onModelChange();
  }

  toggleDropoffCustomAddress() {
    this.dropoffCustomAddressToggle = !this.dropoffCustomAddressToggle;
    if (!this.dropoffCustomAddressToggle) {
      this.initDropoffSchedulesList();
    } else {
      this.dropoffSchedulesList = [];
    }
    this.onModelChange();
  }

  getPickupIteneraryRoute(itinerary: RouteItinerary): Route {
    let result: Route;

    for (const pickup of this.pickupList) {
      for (const routeItinerary of pickup.value.iteneraries) {
        if (routeItinerary.id === itinerary.id) {
          result = pickup.value;
          break;
        }
        if (result) {
          break;
        }
      }
    }

    return result;
  }

  getDropoffIteneraryRoute(itinerary: RouteItinerary): Route {
    let result: Route;

    for (const pickup of this.dropoffList) {
      for (const routeItinerary of pickup.value.iteneraries) {
        if (routeItinerary.id === itinerary.id) {
          result = pickup.value;
          break;
        }
        if (result) {
          break;
        }
      }
    }

    return result;
  }

  getDropoffDestinationItinerary(): RouteItinerary {
    let result;
    if (this.dropoffList && this.dropoffList.length > 0) {
      const iteneraries = this.dropoffList[0].value.iteneraries;
      if (iteneraries && iteneraries.length > 0) {
        result = iteneraries[0];
      }
    }
    return result;
  }

  isToShowCustomPickupAddress(): boolean {
    if(this._globalService.isMoreThanOneTransportComponent) {
      if(this.pickupItenerariesList.length === 0) {
        return false;
      }
      return true;
    }
    if( this.pickupCustomAddressToggle || !this._globalService.isMoreThanOneTransportComponent && this.pickupItenerariesList.length === 0 && this.dropoffItenerariesList.length === 0) {
      return true;
    }
    return false;
  }
  isToShowCustomReturnAddress(): boolean {
    if(this._globalService.isMoreThanOneTransportComponent) {
      if(this.dropoffItenerariesList.length === 0) {
        return false;
      }
      return true;
    }
    if( this.dropoffCustomAddressToggle || !this._globalService.isMoreThanOneTransportComponent && this.dropoffItenerariesList.length === 0 && this.pickupItenerariesList.length === 0 && this.dropoffItenerariesList.length === 0 ) {
      return true;
    }
    return false;
  }

  get selectedPickupRoute(): Route {
    return this._selectedPickupRoute;
  }

  set selectedPickupRoute(value: Route) {
    this._selectedPickupRoute = value;
    this.pickupIteraction.route = this._selectedPickupRoute;
  }

  set selectedDropoffRoute(value: Route) {
    this._selectedDropoffRoute = value;
    this.dropoffIteraction.route = this._selectedDropoffRoute;
  }

  get selectedPickupItenerary(): RouteItinerary {
    return this._selectedPickupItenerary;
  }

  set selectedPickupItenerary(value: RouteItinerary) {
    this._selectedPickupItenerary = value;
    this.selectedPickupRoute = this.getPickupIteneraryRoute(value);

    if (this._selectedPickupItenerary.schedules && this._selectedPickupItenerary.schedules.length > 0) {
      this._selectedPickupSchedule = this._selectedPickupItenerary.schedules[0];
    } else {
      this._selectedPickupSchedule = undefined;
    }

    this.initPickupSchedulesList();
    this.onModelChange();
  }

  get selectedDropoffItenerary(): RouteItinerary {
    return this._selectedDropoffItenerary;
  }

  set selectedDropoffItenerary(value: RouteItinerary) {
    this._selectedDropoffItenerary = value;
    this.selectedDropoffRoute = this.getDropoffIteneraryRoute(value);

    this.onModelChange();
  }

  get selectedPickupSchedule(): RouteSchedule {
    return this._selectedPickupSchedule;
  }

  set selectedPickupSchedule(value: RouteSchedule) {
    this._selectedPickupSchedule = value;
    this.onModelChange();
  }

  get selectedDropoffSchedule(): RouteSchedule {
    return this._selectedDropoffSchedule;
  }

  set selectedDropoffSchedule(value: RouteSchedule) {
    this._selectedDropoffSchedule = value;
    this.onModelChange();
  }

  get customPickupFrom(): string {
    return this._customPickupFrom;
  }

  set customPickupFrom(value: string) {
    this._customPickupFrom = value;
    this.onModelChange();
  }

  get customDropoffTo(): string {
    return this._customDropoffTo;
  }

  set customDropoffTo(value: string) {
    this._customDropoffTo = value;
    this.onModelChange();
  }
}
