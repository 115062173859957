import {Component, OnInit, ViewChild} from '@angular/core';
import {ElementOptions, ElementsOptions, StripeCardComponent} from 'ngx-stripe';
import {SettingsService} from '../shared/api/resources/settings/settings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalService} from '../shared/global.service';
import {WidgetService} from '../shared/api/widget/widget.service';
import {ToasterService} from '../shared/atoms/toaster/toaster.service';
import {StaticUtils} from '../shared/utils/static-utils';
import {LocalStripeService} from '../shared/stripe/stripe.service';
import {CartService} from '../shared/cart/cart.service';
import {BookingShoppingCart} from '../shared/api/model/BookingShoppingCart';
import{GoogleAnalyticsService} from '../google-analytics.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [LocalStripeService]
})
export class PaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent, {static: false}) card: StripeCardComponent;
 
  stripeError: boolean = false;
  stripeErrorMsg: string = '';
  creatingReservation: boolean = false;
  cart: BookingShoppingCart;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#8ca0b3',
        lineHeight: '36px',
        fontWeight: 400,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#8ca0b3'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService,
    private _stripeService: LocalStripeService,
    private _settings: SettingsService,
    private _widgetService: WidgetService,
    private _cartService: CartService,
    private _toasterService: ToasterService,
    private gtmService: GoogleTagManagerService,
    private googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    // this.countries = this._settings.countries.map(country => ({label: country.name, value: country}));
    setTimeout(async () => this.cart = await this._cartService.getCart());
  }

  public async buy(): Promise<void> {
    this._globalService.loading = true;
    this.creatingReservation = true;
    this._globalService.processingPayment = true;
    this.stripeError = false;
    this.stripeErrorMsg = '';

    let intentResult: any;
    let result: any;
    try {
      const cart = await this._cartService.getCart();
      intentResult = await this._widgetService.shoppingCartStripePaymentIntent(cart.id, cart.guid).toPromise();
      this._globalService.booking = intentResult.booking;
      result = await this._stripeService.handleCardPayment(intentResult.clientSecret, this.card.getCard());
    } catch (errorResult) {
      if (intentResult) {
        this._widgetService.bookStripePaymentError(intentResult).toPromise().then();
      }
      this.handleError(errorResult.error ? errorResult.error.message : errorResult.message);
    } finally {
      this._globalService.loading = false;
      this.creatingReservation = false;
      this._globalService.processingPayment = false;

      if (result && result.error) {
        // The payment has resulted in an error
        this._handleStripeError(result.error.message, intentResult);
      } else if (result && result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        // The payment has succeeded
        // sending required data to analytics function
        this.cart = await this._cartService.getCart();
        this._createAnalyticsEvent(this.cart);

        this._widgetService.bookStripePaymentSuccess(intentResult).toPromise().then();
        await this._router.navigate(['../thankyou/'], { relativeTo: this._route, queryParamsHandling: 'merge' });
      } else if (result && result.paymentIntent && result.paymentIntent.status) {
        // The payment did not succeed
        const msg = 'Something unexpected happened\nPayment status: ' + result.paymentIntent.status;
        this._handleStripeError(msg, intentResult);
      }
    }
  }

  private _handleStripeError(msg: string, intentResult?: any): void {
    this.stripeError = true;
    this.stripeErrorMsg = msg;
    
    if (intentResult) {
      this._widgetService.bookStripePaymentError(intentResult).toPromise().then();
    }
  }

  private _createAnalyticsEvent(cart: object): void {
    // console.log('cart', cart);
    // const items = [];
    const products = [];
    // let position = 1;

    cart['bookings'].forEach( booking => {
      products.push({
          'name': booking.event.product.name,
          'id': booking.event.product.id.toString(),
          'quantity': booking.paxTotal,
          'price': booking.billingTotal,
          'coupon': booking.attachPromocode,
          // 'listposition': position
        },
        // cart
      );
      // position++;
    });

    // const parsedAmount = cart['billingTotal'];

    // this.googleAnalyticsService.purchaseEmitter(
    //   cart['id'],
    //   cart['billingTotal'],
    //   cart['attachPromocode'],
    //   // parsedAmount
    //   );

    // push GTM data layer with a custom event / prodicts purchased
    const gtmTag = {
      event: 'purchase',
      ecommerce: {
        'currencyCode': 'EUR',
        'purchase': {
            'actionField': {
                'id': cart['id'], // id do carrinho
                'affiliation': 'Pluralo Widget',
                'revenue': cart['billingTotal'], //billingTotal do carrinho
                'coupon': cart['attachPromocode'], // attachPromocode do carrinho
          },
          products // dados do carrinho
        }
      }
    };
    this.gtmService.pushTag(gtmTag);
    // console.log('purchased custom event');
    // console.log('dataLayer after payment: ======> ', window.dataLayer);



  }

  formatErrorMessage(message: string): string {
    return message.replace(/\n/g, '<br>');
  }

  isStripeCardValid() {
    let result = false;
    if (this.card) {
      // @ts-ignore
      result = !this.card.getCard()._empty && !this.card.getCard()._invalid;
    }
    return result;
  }

  handleError(errorMessage: string) {
    this._globalService.errorMessage = errorMessage;
    this._globalService.loading = false;
    this.creatingReservation = false;
    this._router.navigate(['../error/'], { relativeTo: this._route, queryParamsHandling: 'merge' });
  }

  get totalPrice(): string {
    return StaticUtils.convertValueToCurrency(this.cart && this.cart.billingTotal ? this.cart.billingTotal : 0);
  }
}
