import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalService} from '../shared/global.service';
import * as moment from 'moment';
import {WidgetService} from '../shared/api/widget/widget.service';
import {StaticUtils} from '../shared/utils/static-utils';
import {BookingShoppingCart} from '../shared/api/model/BookingShoppingCart';
import {CartService} from '../shared/cart/cart.service';
import {Customer} from '../shared/api/model/customer';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  moment = moment;
  utils = StaticUtils;
  cart: BookingShoppingCart;
  customer: Customer;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService,
    private _widgetService: WidgetService,
    private _cartService: CartService) { }

  ngOnInit() {
    this.customer = this._globalService.customer;
    setTimeout(async () => {
      this.cart = await this._cartService.getCart();
      this._cartService.clearCart();
    });
  }

  done() {
    this._globalService.clean();
    this._router.navigate(['../home'], { relativeTo: this._route, queryParamsHandling: 'merge' });
  }

  getTicketNumbers(): string[] {
    return this.cart && this.cart.bookings ? this.cart.bookings.map(booking => this.getTicketNumber(booking.id)) : [];
  }

  getTicketNumber(id: number): string {
    const repeatValue = 5 - id.toString().length;
    let result = '#';

    if (repeatValue >= 1) {
      result = '#' + '0'.repeat(repeatValue) + id;
    } else {
      result = '#' + id;
    }
    return result;
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date;
  }
}
