import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import * as moment from "moment";
import {
  NgbDateStruct,
  NgbDate,
  NgbDatepickerNavigateEvent,
  NgbDatepicker,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";

interface MarkedDay {
  date: moment.Moment;
  bgColor?: string;
  color?: string;
  message?: string;
}

@Component({
  selector: "app-date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.scss"],
})
export class DateInputComponent implements OnInit {
  @Input() useNativeMobileInput = true;
  @Input() insidePopover = true;
  @Input() isRange = false;
  @Input() position = "bottom";
  @Input() navigation = "arrows";
  @Input() placeholder = "";
  @Input() startDate: moment.Moment;
  @Input() endDate: moment.Moment;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() displayMonths = 1;
  @Input() asyncNavigation = false;
  @Input() notMarkedDaysConfig: { bgColor: string; color: string };
  @Input() disableNonMarkedDays = false;

  @Output() onChange = new EventEmitter<{
    start: moment.Moment;
    end: moment.Moment;
  }>();
  @Output() onNavigation = new EventEmitter<moment.Moment>();

  @ViewChild(NgbDatepicker, { static: false }) ngbDatepicker: NgbDatepicker;
  @ViewChild(NgbInputDatepicker, { static: false })
  ngbInputDatepicker: NgbInputDatepicker;
  @ViewChild("datePickerInput", { static: false }) datePickerInput: ElementRef;

  private _markedDays: MarkedDay[];
  private _disabled: boolean;
  private _onNavigationPromise: Promise<moment.Moment>;
  private _value: any;

  public isMobile: boolean;
  public hoveredDate: NgbDate;
  public fromDate: NgbDate;
  public toDate: NgbDate;

  private _asyncCycleComplete: boolean;

  constructor() {}

  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.onChange.emit(this._value);
      if (this.datePickerInput) this.datePickerInput.nativeElement.click();
    }
  }

  ngOnInit() {
    this.isMobile =
      this.useNativeMobileInput &&
      /Android|iPhone/i.test(window.navigator.userAgent);
    this.fromDate = this.toNgbDate(this.startDate);
    this.toDate = this.toNgbDate(this.endDate);

    if (this.datePickerInput) this.datePickerInput.nativeElement.click();
  }

  public onDateSelection(date: NgbDate | any): void {
    date = NgbDate.from(date);

    if (!(this.disableNonMarkedDays && !this.getMarkedDay(date))) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (
        this.isRange &&
        this.fromDate &&
        !this.toDate &&
        date.after(this.fromDate)
      ) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }

      this.onChange.emit({
        start: this.toMoment(this.fromDate),
        end: this.toMoment(this.toDate),
      });

      if (this.insidePopover) {
        this.ngbInputDatepicker.close();
      }
    }
  }

  public onMobileDateSelection(event: any) {
    const momentDate = moment(event.target.value);
    const ngbDate = this.toNgbDate(momentDate);
    this.onDateSelection(ngbDate);
  }

  public onNavigate(event: NgbDatepickerNavigateEvent): void {
    const current = event.current as NgbDateStruct;
    const next = event.next as NgbDateStruct;

    let from: NgbDate;
    if (current) {
      current.day = 1;
      from = NgbDate.from(current);
    }

    next.day = 1;
    const to: NgbDate = NgbDate.from(next);

    if (!from || !from.equals(to)) {
      if (this.asyncNavigation) {
        if (!this._asyncCycleComplete) {
          this._asyncCycleComplete = true;
          event.preventDefault();
          this.onNavigation.emit(this.toMoment(to));
        }
      } else {
        this.onNavigation.emit(this.toMoment(to));
      }
    }
  }

  public isHovered(date: NgbDate): boolean {
    date = NgbDate.from(date);
    return (
      date &&
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInsideFromTo(date: NgbDate): boolean {
    date = NgbDate.from(date);
    return date && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRangeLimits(date: NgbDate): boolean {
    date = NgbDate.from(date);
    return (
      date &&
      (date.equals(this.fromDate) ||
        date.equals(this.toDate) ||
        (this.isRange && this.isInsideFromTo(date)) ||
        this.isHovered(date))
    );
  }

  public addRangeClass(date: NgbDate): boolean {
    date = NgbDate.from(date);
    return (
      (this.isRange && this.isRangeLimits(date)) ||
      (date && date.equals(this.fromDate))
    );
  }

  public addFadedClass(date: NgbDate): boolean {
    return this.isRange && (this.isHovered(date) || this.isInsideFromTo(date));
  }

  public getMarkedDayBgColor(date: NgbDate): string {
    date = NgbDate.from(date);

    let color;
    if (this._markedDays) {
      const markedDay = this.getMarkedDay(date);
      if (markedDay) {
        color = markedDay.bgColor ? markedDay.bgColor : "rgba(0,169,240,.3)";
      } else if (this.notMarkedDaysConfig && this.notMarkedDaysConfig.bgColor) {
        color = this.notMarkedDaysConfig.bgColor;
      }
    }

    return color;
  }

  public getMarkedDayColor(date: NgbDate): string {
    date = NgbDate.from(date);
    let color;
    if (this._markedDays) {
      const markedDay = this.getMarkedDay(date);
      if (markedDay) {
        color = markedDay.color ? markedDay.color : "#000000";
      } else if (this.notMarkedDaysConfig && this.notMarkedDaysConfig.color) {
        color = this.notMarkedDaysConfig.color;
      }
    }

    return color;
  }

  public handleDayClick($event: MouseEvent, date: NgbDate): void {
    if (this.disableNonMarkedDays && !this.getMarkedDay(date)) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  public getMarkedDay(date: NgbDate): MarkedDay {
    date = NgbDate.from(date);
    return this._markedDays
      ? this._markedDays.find((day) =>
          day.date.isSame(this.toMoment(date), "day")
        )
      : undefined;
  }

  public getFormattedInputLabel(): string {
    let result = "";

    const fromMoment = this.toMoment(this.fromDate);
    if (fromMoment && fromMoment.isValid()) {
      result = fromMoment.format("DD/MM/YYYY");
    }

    if (this.isRange) {
      result += " - ";
      const toMoment = this.toMoment(this.fromDate);
      if (toMoment && toMoment.isValid()) {
        result += toMoment.format("DD/MM/YYYY");
      }
    }

    return result;
  }

  public handleInputChange(value: string): void {
    if (!this.isRange) {
      const valueArr = value.trim().split("/");
      if (valueArr.length === 3) {
        const momentValue = moment(
          valueArr[2] + "-" + valueArr[1] + "-" + valueArr[0]
        );
        if (momentValue.isValid()) {
          const newDate = this.toNgbDate(momentValue);
          if (newDate && !newDate.equals(this.fromDate)) {
            this.ngbInputDatepicker.startDate = newDate;
            this.ngbInputDatepicker.open();
            this.ngbInputDatepicker.close();
            this.fromDate = newDate;
          }
        }
      }
    } else {
      const valueArr = value.trim().split(" - ");
      if (valueArr.length === 2) {
        const fromArr = valueArr[0].split("/");
        if (fromArr.length === 3) {
          const momentValue = moment(
            fromArr[2] + "-" + fromArr[1] + "-" + fromArr[0]
          );
          if (momentValue.isValid()) {
            const newDate = this.toNgbDate(momentValue);
            if (newDate && !newDate.equals(this.fromDate)) {
              this.ngbInputDatepicker.startDate = newDate;
              this.ngbInputDatepicker.open();
              this.ngbInputDatepicker.close();
              this.fromDate = newDate;
            }
          }
        }
        const toArr = valueArr[1].split("/");
        if (toArr.length === 3) {
          const momentValue = moment(
            toArr[2] + "-" + toArr[1] + "-" + toArr[0]
          );
          if (momentValue.isValid()) {
            const newDate = this.toNgbDate(momentValue);
            if (newDate && !newDate.equals(this.toDate)) {
              this.toDate = newDate;
            }
          }
        }
      }
    }
    this.onChange.emit({
      start: this.toMoment(this.fromDate),
      end: this.toMoment(this.toDate),
    });
  }

  public toNgbDate(date: moment.Moment): NgbDate {
    return date
      ? new NgbDate(date.get("year"), date.get("month") + 1, date.get("date"))
      : null;
  }

  public toMoment(date: NgbDate): moment.Moment {
    if (!date || !date.day || !date.month || !date.year) {
      return null;
    }
    const day = date.day < 10 ? "0" + date.day.toString() : date.day.toString();
    const month =
      date.month < 10 ? "0" + date.month.toString() : date.month.toString();
    const year = date.year.toString();

    return moment(year + "-" + month + "-" + day);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input("disabled")
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get markedDays(): MarkedDay[] {
    return this._markedDays;
  }

  @Input("markedDays")
  set markedDays(value: MarkedDay[]) {
    this._markedDays = value;
  }

  get onNavigationPromise(): Promise<moment.Moment> {
    return this._onNavigationPromise;
  }

  @Input("onNavigationPromise")
  set onNavigationPromise(value: Promise<moment.Moment>) {
    if (value) {
      this._onNavigationPromise = value;
      this._onNavigationPromise.then((next: moment.Moment) => {
        if (this._asyncCycleComplete) {
          if (this.ngbDatepicker) {
            this.ngbDatepicker.navigateTo(this.toNgbDate(next));
          }
          if (this.ngbInputDatepicker) {
            this.ngbInputDatepicker.navigateTo(this.toNgbDate(next));
          }
          this._asyncCycleComplete = false;
        }
      });
    }
  }
}
